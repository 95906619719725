import React, {useContext} from "react";
import styled from "styled-components";
import { Link } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap";

import UseAnimations from 'react-useanimations';
import instagram from 'react-useanimations/lib/instagram'
import youtube from 'react-useanimations/lib/youtube'
import twitter from 'react-useanimations/lib/twitter'
import github from 'react-useanimations/lib/github'



import { Section, Title, Text, Span, ButtonOutline } from "../../components/Core";
import imgPhoto from "../../assets/image/png/about_graphic.png";

import GlobalContext from "../../context/GlobalContext";


const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const About = ({ hero = true, bg = "dark", ...rest }) => {

  const gContext = useContext(GlobalContext);
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <img src={imgPhoto} alt="folio" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  AppSwagg
                </Title>
                <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  We're design & development studio creating simple, intuitive, and functional experiences for the digital product space! Check out our work, creative content and free resources. Don't forget to follow AppSwagg on social media for fresh and interesting content!
                </Text>
                <Text color="light" className="mt-4">
                  Have any questions about the content we create or do you want to inquire about our services?
                </Text>
                <Text color="light" className="mt-5 font-weight-bold">
                  Reach out to us today!
                </Text>
                <ButtonOutline onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleContact();
                  }}>Contact now</ButtonOutline>
                <div className="mt-5 d-flex">
                  <Link to='https://www.youtube.com/channel/UCbZE1__xnFHF_IggJPPpdfg'>
                    <UseAnimations animation={youtube} size={56} strokeColor={'white'} />
                  </Link>
                  <Link to='https://www.instagram.com/app.swagg/'>
                    <UseAnimations animation={instagram} size={56} strokeColor={'white'} />
                  </Link>
                  <Link to='https://twitter.com/appswagg'>
                    <UseAnimations animation={twitter} size={56} strokeColor={'white'} />
                  </Link>
                  <Link to='https://github.com/AppSwagg'>
                    <UseAnimations animation={github} size={56} strokeColor={'white'} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
