import React from "react";
import { Link } from "gatsby";
import { Title } from "../Core";
import logoImage from '../../assets/logo.svg'

const Logo = ({ color = "front", height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <img style={{width: '120px'}} src={logoImage}/>
    </Link>
  );
};

export default Logo;
