import React, {useContext, useState} from "react";

import { Form } from "react-bootstrap";

import { Input, TextArea, Button, Title, Box } from "../Core";

import GlobalContext from "../../context/GlobalContext";

import firebase from 'gatsby-plugin-firebase'

const ContactForm = ({ theme = "dark", ...rest }) => {

  const gContext = useContext(GlobalContext);
  const [error, setError] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()
    const form = event.target
    let data = {}
    const url = 'https://formspree.io/mdowjden'
    data.email = form.elements.email.value
    data.message = form.elements.message.value
    firebase.analytics().logEvent("email_sent")
    fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    }).then(res => gContext.toggleContact()).catch(e => {
      setError('Something went wrong...')
      setTimeout(() => {setError(null)}, 3000)
    })
  }

  return (
    <Form
      onSubmit={handleSubmit}
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      {...rest}
    >
      {/* You still need to add the hidden input with the form name to your JSX form */}
      <input type="hidden" name="form-name" value="contact" />
      {
        error && <Box p={4} bg={'red'}>{error}</Box>
      }
      <div className="mt-4">
        <Input type="email" placeholder="Email address" required name="email" />
      </div>
      <div className="mt-4 ">
        <TextArea
          rows={4}
          placeholder="Write your message"
          required
          name="message"
        />
      </div>
      <div className="mt-4 mt-lg-5">
        <Button arrowRight variant="primary" type="submit">
          SEND
        </Button>
      </div>
    </Form>
  );
};

export default ContactForm;
